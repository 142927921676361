import Layout from '@/views/admin/layout/Layout'

/**
* path: ''                       请使用全地址，例如：/system/user 或者 http:www.xxx.com 便于路由定位
* hidden: true                   设为true，则不会显示到菜单栏
* alwaysShow: true               设为true，则永远显示一级栏目
* redirect: noredirect           默认重定向，当没有找到地址时重定向的去向
* name:'router-name'             <keep-alive>需要使用 (must set!!!)
* meta : {
    title: 'title'               显示的名称
    icon: 'svg-name'             显示的图标
    roles: ['']                  权限
    isSkip: true                 如果菜单可以有下级且自己本身也需要打开页面，则配置isSkip
  }
  注意：如果有第三级及多级菜单，则path配全地址，“/path1/path2/...”
**/

const routerMap = [
  {
    path: '/',
    component: Layout,
    hidden: true,
    redirect: '/dashboard',
    meta: { title: '首页', icon: 'fa fa-gear' }, // , roles: ['System']
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/admin/dashboard/index'),
        meta: { isPageHead: true, title: '仪表盘', icon: 'fa fa-dashboard' }
      }
    ]
  },
  {
    path: '/order',
    name: 'order',
    component: Layout,
    meta: { title: '订单管理', icon: 'el-icon-shopping-cart-1', roles: ['Permission.Order'] },
    redirect: '/order/list',
    children: [
      {
        path: '/order/list',
        name: 'pageOrderList',
        component: () => import('@/views/admin/order/list'),
        meta: { isPageHead: true, parent: '订单管理', title: '租赁订单', icon: 'el-icon-shopping-cart-1', roles: ['Permission.Order.Manage'] }
      },
      {
        path: '/order/details',
        name: 'pageOrderDetails',
        hidden: true,
        component: () => import('@/views/admin/order/details'),
        meta: { isPageHead: true, parent: '订单管理', title: '订单详情' }
      },
      {
        path: '/agent/profit',
        name: 'pageAgentProfit',
        component: () => import('@/views/admin/agent/profit'),
        meta: { isPageHead: true, parent: '订单管理', title: '流水查询', icon: 'el-icon-shopping-cart-1', roles: ['Permission.Order.Manage', 'Permission.Agent.View'] }
      }
    ]
  },
  {
    path: '/device',
    name: 'device',
    component: Layout,
    meta: { title: '设备管理', icon: 'el-icon-receiving', roles: ['Permission.Device'] },
    redirect: '/device/list',
    children: [
      {
        path: '/device/list',
        name: 'pageDeviceList',
        component: () => import('@/views/admin/device/list'),
        meta: { isPageHead: true, parent: '设备管理', title: '设备管理', icon: 'el-icon-receiving', roles: ['Permission.Device.View'] }
      },
      {
        path: '/device/details',
        name: 'pageDeviceDetails',
        hidden: true,
        component: () => import('@/views/admin/device/details'),
        meta: { isPageHead: true, parent: '设备管理', title: '设备详情' }
      },
      {
        path: '/device/types',
        name: 'pageDeviceTypes',
        component: () => import('@/views/admin/device/types'),
        meta: { isPageHead: true, parent: '设备管理', title: '型号管理', icon: 'el-icon-receiving', roles: ['Permission.Device.TypeView'] }
      },
      {
        path: '/device/terminals',
        name: 'pageDeviceterminals',
        component: () => import('@/views/admin/device/terminals'),
        meta: { isPageHead: true, parent: '设备管理', title: '终端管理', icon: 'el-icon-receiving', roles: ['Permission.Device.View'] }
      },
      {
        path: '/device/upgrades',
        name: 'pageDeviceUpgrades',
        component: () => import('@/views/admin/device/upgrades'),
        meta: { isPageHead: true, parent: '设备管理', title: '升级包管理', icon: 'el-icon-receiving', roles: ['Permission.Device.View'] }
      },
      {
        path: '/device/terminalLogs',
        name: 'pageTerminalLogs',
        hidden: true,
        component: () => import('@/views/admin/device/terminalLogs'),
        meta: { isPageHead: true, parent: '设备管理', title: '输液宝日志' }
      }
    ]
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: Layout,
    meta: { title: '商家管理', icon: 'el-icon-eleme', roles: ['Permission.Merchant', 'Permission.Financial'] },
    redirect: '/merchant/list',
    children: [
      {
        path: '/merchant/list',
        name: 'pageMerchantList',
        component: () => import('@/views/admin/merchant/list'),
        meta: { isPageHead: true, parent: '商家管理', title: '商家列表', icon: 'el-icon-eleme', roles: ['Permission.Merchant.View'] }
      },
      {
        path: '/merchant/details',
        name: 'pageMerchantDetails',
        hidden: true,
        component: () => import('@/views/admin/merchant/details'),
        meta: { isPageHead: true, parent: '商家管理', title: '商家详细' }
      },
      {
        path: '/merchant/categorys',
        name: 'pageMerchantCategorys',
        component: () => import('@/views/admin/merchant/categorys'),
        meta: { isPageHead: true, parent: '商家管理', title: '商家类别', icon: 'el-icon-receiving', roles: ['Permission.Merchant.CategoryView'] }
      },
      {
        path: '/merchant/statistics',
        name: 'pageMerchantStatistics',
        component: () => import('@/views/admin/merchant/statistics'),
        meta: { isPageHead: true, parent: '商家管理', title: '营业统计', icon: 'el-icon-receiving', roles: ['Permission.Financial.Statistics'] }
      },
      {
        path: '/merchant/position',
        name: 'pageMerchantPosition',
        component: () => import('@/views/admin/merchant/position'),
        meta: { isPageHead: true, parent: '商家管理', title: '投放位置', icon: 'el-icon-receiving', roles: ['Permission.Merchant.Manage'] }
      },
      {
        path: '/merchant/record',
        name: 'pageMerchantRecord',
        hidden: true,
        component: () => import('@/views/admin/merchant/record'),
        meta: { isPageHead: true, parent: '商家管理', title: '商家档案' }
      },
    ]
  },
  {
    path: '/agent',
    name: 'agent',
    alwaysShow: true,
    component: Layout,
    meta: { title: '代理管理', icon: 'el-icon-office-building', roles: ['Permission.Agent'] },
    redirect: '/agent/list',
    children: [
      {
        path: '/agent/list',
        name: 'pageAgentList',
        component: () => import('@/views/admin/agent/list'),
        meta: { isPageHead: true, parent: '代理管理', title: '代理列表', icon: 'el-icon-office-building', roles: ['Permission.Agent.View'] }
      }
    ]
  },
  {
    path: '/member',
    name: 'member',
    component: Layout,
    meta: { title: '会员管理', icon: 'el-icon-user', roles: ['Permission.Member'] },
    redirect: '/member/list',
    children: [
      {
        path: '/member/list',
        name: 'pageMemberList',
        component: () => import('@/views/admin/member/list'),
        meta: { isPageHead: true, parent: '会员管理', title: '会员列表', icon: 'el-icon-user', roles: ['Permission.Member.View'] }
      },
      {
        path: '/member/questionBack',
        name: 'pageMemberQuestionBack',
        component: () => import('@/views/admin/member/questionBack'),
        meta: { isPageHead: true, parent: '会员管理', title: '问题反馈', icon: 'el-icon-user', roles: ['Permission.Member.Feedback'] }
      },
      {
        path: '/member/capitallogs',
        name: 'pageMemberCapitallogs',
        hidden: true,
        component: () => import('@/views/admin/member/capitallogs'),
        meta: { isPageHead: true, parent: '会员管理', title: '余额日志' }
      }
    ]
  },
  {
    path: '/article',
    name: 'article',
    component: Layout,
    meta: { title: '文章管理', icon: 'el-icon-document', roles: ['Permission.Article'] },
    redirect: '/article/list',
    children: [
      {
        path: '/article/classify',
        name: 'pageArticleClassify',
        component: () => import('@/views/admin/article/classify'),
        meta: { isPageHead: true, parent: '文章管理', title: '分类管理', icon: 'el-icon-document', roles: ['Permission.Article.Manage'] }
      },
      {
        path: '/article/list',
        name: 'pageArticleList',
        component: () => import('@/views/admin/article/list'),
        meta: { isPageHead: true, parent: '文章管理', title: '文章列表', icon: 'el-icon-document', roles: ['Permission.Article.View'] }
      }
    ]
  },
  {
    path: '/system',
    name: 'system',
    component: Layout,
    meta: { title: '系统管理', icon: 'fa fa-gear', roles: ['Permission.System'] },
    redirect: '/system/sysConfig',
    children: [
      {
        path: '/system/sysConfig',
        name: 'sysConfig',
        component: () => import('@/views/admin/system/sysConfig'),
        meta: { isPageHead: true, parent: '系统管理', title: '系统配置', icon: 'fa fa-users', roles: ['Permission.System.SystemConfig'] }
      },
      {
        path: '/system/appNotification',
        name: 'pageAppNotification',
        component: () => import('@/views/admin/system/appNotification'),
        meta: { isPageHead: true, parent: '系统管理', title: '消息通知', icon: 'fa fa-users', roles: ['Permission.System.SystemConfig'] }
      },
      {
        path: '/system/role',
        name: 'role',
        component: () => import('@/views/admin/system/role'),
        meta: { isPageHead: true, parent: '系统管理', title: '角色管理', icon: 'fa fa-users', roles: ['Permission.System.RoleManage'] }
      },
      {
        path: '/system/user',
        name: 'user',
        component: () => import('@/views/admin/system/user'),
        meta: { isPageHead: false, parent: '系统管理', title: '用户管理', icon: 'fa fa-user', roles: ['Permission.System.AdminManage'] }
      },
      {
        path: '/system/loginLogs',
        name: 'loginLogs',
        component: () => import('@/views/admin/system/loginLogs'),
        meta: { isPageHead: true, parent: '系统管理', title: '登录日志', icon: 'fa fa-file-text-o', roles: ['Permission.System.LoginLogs'] }
      },
      {
        path: '/system/operationLogs',
        name: 'operationLogs',
        component: () => import('@/views/admin/system/operationLogs'),
        meta: { isPageHead: true, parent: '系统管理', title: '操作日志', icon: 'fa fa-book', roles: ['Permission.System.HandleLogs'] }
      }
    ]
  },
  {
    path: '/advert',
    name: 'advert',
    alwaysShow: true,
    component: Layout,
    meta: { title: '广告管理', icon: 'el-icon-copy-document', roles: ['Permission.Ad'] },
    redirect: '/advert/list',
    children: [
      {
        path: '/advert/list',
        name: 'pageAdvertList',
        component: () => import('@/views/admin/advert/list'),
        meta: { isPageHead: true, parent: '广告管理', title: '广告列表', icon: 'el-icon-copy-document', roles: ['Permission.Ad.Manage'] }
      }
    ]
  },
  /* {
    path: '/data',
    name: 'data',
    component: Layout,
    meta: { title: '数据统计', icon: 'fa fa-user', roles: ['System'] },
    redirect: '/data/basic',
    children: [
      {
        path: '/data/basic',
        name: 'basic',
        component: () => import('@/views/admin/data/basic'),
        meta: { title: '基础数据', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/data/keeping',
        name: 'keeping',
        component: () => import('@/views/admin/data/keeping'),
        meta: { title: '留存率', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/data/lossNode',
        name: 'lossNode',
        component: () => import('@/views/admin/data/lossNode'),
        meta: { title: '流失节点统计', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/data/online',
        name: 'online',
        component: () => import('@/views/admin/data/online'),
        meta: { title: '在线人数', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/data/customs',
        name: 'customs',
        component: () => import('@/views/admin/data/customs'),
        meta: { title: '关卡统计', icon: 'fa fa-user', roles: ['System'] }
      }
    ]
  },
  {
    path: '/material',
    name: 'material',
    component: Layout,
    alwaysShow: true,
    meta: { title: '游戏数据', icon: 'fa fa-user', roles: ['System'] },
    redirect: '/material/weapon',
    children: [
      {
        path: '/material/weapon',
        name: 'weapon',
        component: () => import('@/views/admin/material/weapon'),
        meta: { title: '武器状态', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/material/playingMain',
        name: 'playingMain',
        component: () => import('@/views/admin/material/playingMain'),
        meta: { title: '主线副本', icon: 'fa fa-user', roles: ['System'] }
      }
    ]
  } */
]

export default routerMap