import { getSystemConfigStorage, setSystemConfigStorage,
         getSkinColorCode, setSkinColorCode } from "@/utils/storages"
const common = {
  state: {
    systemConfig: getSystemConfigStorage() || null,
    skinColorCode: getSkinColorCode() || null,
    sysPageHeader: null
  },
  mutations: {
    setSystemConfig(state, item) {
      state.systemConfig = item
    },
    setSkinColorCode(state, item) {
      state.skinColorCode = item
    },
    setSysPageHeader(state, item) {
      state.sysPageHeader = item || null
    }
  },
  actions: {
    setSystemConfig(context, item) {
      context.commit("setSystemConfig", item)
      setSystemConfigStorage(item)
    },
    setSkinColorCode(context, item) {
      context.commit("setSkinColorCode", item)
      setSkinColorCode(item)
    },
    setSysPageHeader(context, item) {
      context.commit("setSysPageHeader", item)
    }
  }
}
export default common
