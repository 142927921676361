<style scoped lang="scss">
  /deep/ .comp-password-dialog {
    max-width: 520px;
  }
</style>

<template>
  <div>
    <el-dialog title="修改密码" custom-class="c-el-dialog comp-password-dialog" :visible.sync="showDialog" 
    :before-close="_close" :close-on-click-modal="false" :append-to-body="true">
      <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="100px" size="small">
        <el-form-item label="原密码" prop="currentPassword">
          <el-input type="password" v-model="dialogData.currentPassword" :placeholder="formRules.currentPassword[0].message"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" v-model="dialogData.newPassword" :placeholder="formRules.newPassword[0].message"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="surePassword">
          <el-input type="password" v-model="dialogData.surePassword" placeholder="请再次输入密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="_close()">取消</el-button>
        <el-button size="small" type="primary" plain @click="_save()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { changePassword } from '@/api/system/user'
export default {
  name: "password",
  data() {
    var validSurePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"))
      } else if (value !== this.dialogData.newPassword) {
        callback(new Error("两次输入密码不一致"))
      } else {
        callback()
      }
    }
    return {
      showDialog: false,
      dialogData: {
        currentPassword: "",
        newPassword: "",
        surePassword: ""
      },
      formRefName: 'formRefName',
      formRules: {
        currentPassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' },{ min: 6, max: 32, message: '长度在 6 到 32 个字符', trigger: 'blur' }],
        surePassword: [{ required: true, validator: validSurePassword, trigger: 'blur' }]
      }
    }
  },
  mounted() {},
  methods: {
    _save() {
      this.$refs[this.formRefName].validate(valid => {
        if (valid) {
          window.$common.fullLoading()
          changePassword(this.dialogData).then(res => {
            console.log(res)
            window.$common.closeFullLoading()
            this.alert("密码修改成功!").then(() => {
              this._close()
            })
          })
        } else {
          return false
        }
      })
    },
    _close() {
      this.showDialog = false
      this.$nextTick(() => {
        if (this.$refs[this.formRefName]) {
          this.$refs[this.formRefName].resetFields()
        }
      })
    }
  }
}
</script>