<style lang="scss">
    .c-header-topnav {
      display: inline-block;
      vertical-align: middle;
      background: $c-bgColor !important;
      .el-menu.el-menu--horizontal {
        border-bottom: 0;
      }
      .el-menu-item,
      .el-submenu .el-submenu__title {
        color: #fff;
      }
      .el-menu-item,
      .el-submenu .el-submenu__title,
      .el-submenu .el-submenu__title i {
        color: #fff !important;
      }
      .el-menu-item.is-active,
      .el-menu-item:hover,
      .el-submenu .el-submenu__title:hover {
        background: $c-bgHoverColor !important;
      }
      .el-menu-item.is-disabled {
        color: #ddd;
        opacity: 0.7;
      }
    }
</style>

<template>
    <!--top导航start-->
    <el-menu :default-active="activeIndex" class="c-header-topnav" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1">处理中心</el-menu-item>
        <el-submenu index="2">
            <template slot="title">我的工作台</template>
            <el-menu-item index="2-1">选项1</el-menu-item>
            <el-menu-item index="2-2">选项2</el-menu-item>
            <el-menu-item index="2-3">选项3</el-menu-item>
            <el-submenu index="2-4">
            <template slot="title">选项4</template>
            <el-menu-item index="2-4-1">选项1</el-menu-item>
            <el-menu-item index="2-4-2">选项2</el-menu-item>
            <el-menu-item index="2-4-3">选项3</el-menu-item>
            </el-submenu>
        </el-submenu>
        <el-menu-item index="3" disabled>消息中心</el-menu-item>
        <el-menu-item index="4"><a href="https://www.ele.me" target="_blank">订单管理</a></el-menu-item>
    </el-menu>
    <!--top导航end-->
</template>

<script>
export default {
    name: "headerBar",
    data() {
        return {
          activeIndex: "1"
        }
    },
    methods: {
      handleSelect() {

      }
    }
}
</script>


