import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { Message } from 'element-ui'
import { getAdminToken } from '@/utils/storages'
import routerMap from '@/router/routerMap'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

/**
* path: ''                       请使用全地址，例如：/system/user 或者 http:www.xxx.com 便于路由定位
* hidden: true                   设为true，则不会显示到菜单栏
* alwaysShow: true               设为true，则永远显示一级栏目
* redirect: noredirect           默认重定向，当没有找到地址时重定向的去向
* name:'router-name'             <keep-alive>需要使用 (must set!!!)
* meta : {
    title: 'title'               显示的名称
    icon: 'svg-name'             显示的图标
    roles: ['']                  权限
    isSkip: true                 如果菜单可以有下级且自己本身也需要打开页面，则配置isSkip
  }
  注意：如果有第三级及多级菜单，则path配全地址，“/path1/path2/...”
**/

let routes = [
  {
    path: '/login',
    name: 'login',
    hidden: true,
    component: () => import('@/views/admin/login/index')
  },
  {
    path: '/refresh',
    name: 'refresh',
    hidden: true,
    component: () => import('@/views/refresh')
  },
  {
    path: '*',
    hidden: true,
    component: () => import('@/views/404')
  }
]

/* const routerContext = require.context('./', true, /\.js$/);
routerContext.keys().forEach((route) => {
    if (route.startsWith('./index')) return;
    if (route.startsWith('./routerMap')) return;
    const routerModule = routerContext(route);
    // 兼容 import export 和 require module.export 两种规范
    routes = routes.concat(routerModule.default || routerModule);
}); */

export const asyncRouterMap = routerMap
export const constantRouterMap = routes

const vueRouter = new Router({
  // mode:'history',  //后端配置404跳转则可开
  base: process.env.BASE_URL, //页面基础路径
  routes: routes,
  scrollBehavior: () => ({ y: 0 })
})

// 验证权限
function hasPermission(roles, permissionRoles) {
  if (!permissionRoles) return true
  return roles.some(role => permissionRoles.indexOf(role) >= 0)
}
const whiteList = ['/login'] // 不重定向白名单

vueRouter.beforeEach((to, from, next) => {
  // 判断权限
  if (getAdminToken()) {
    if (to.path === '/login') {
      next({ path: '/dashboard' })
    } else {
      if (store.getters.roles.length === 0) {
        // 拉取用户信息
        store.dispatch('getUserInfo').then(res => { 
          const roles = res.permissions
          // 根据roles权限生成可访问的路由表
          store.dispatch('setRouters', { roles }).then(() => { 
            // 动态添加可访问路由表
            vueRouter.addRoutes(store.getters.addRouters) 
            // hack方法 确保addRoutes已完成
            next({ ...to, replace: true }) 
          })
        }).catch((err) => {
          console.log(err)
          store.dispatch('loginOut').then(() => {
            Message.error(err || '获取信息出错，请重新登录!')
            location.reload()
            // next({ path: '/login' })
          })
        })
      } else {
        // 没有动态改变权限的需求可直接next()
        if (hasPermission(store.getters.roles, to.meta.roles)) {
          next()
        } else {
          next({ path: '/404', replace: true })
        }
      }
    } 
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login')
    }
  }
})

export default vueRouter

