import request from '@/utils/request'

export function userLogin(data) {
  return request({
    url: '/api/TokenAuth/Authenticate',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/api/services/app/Session/GetCurrentLoginInformations',
    method: 'get',
    params: token
  })
}
