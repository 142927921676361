const getters = {
  token: state => state.user.token,
  name: state => state.user.name,
  user: state => state.user.user,
  roles: state => state.user.roles,
  device: state => state.sidebar.device,
  collapse: state => state.sidebar.collapse,
  active_tab: state => state.sidebar.active_tab,
  tabPanes: state => state.sidebar.tabPanes,
  tagsIncludes: state => state.sidebar.tagsIncludes,
  tagsExcludes: state => state.sidebar.tagsExcludes,
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  systemConfig: state => state.common.systemConfig,
  skin_color_code: state => state.common.skinColorCode,
  sysPageHeader: state => state.common.sysPageHeader,
  enumConfigs: state => state.webSite.enumConfigs
}

export default getters
