<style lang="scss">
    .c-aside-scrollbar {
      height: 100%;
      .el-scrollbar {
        height: 100%;
        .el-scrollbar__wrap {
          overflow-x: hidden;
        }
      }
      .el-menu {
        border-right: 0;
      }
      .el-submenu.is-opened {
        .el-submenu__title, ul, ul div, ul li {
          box-shadow: inset 3px 0 0 0 $c-bgColor;
        }
      }
      .el-submenu.is-opened>.el-submenu__title {
        color: $c-textColor;
        background: #ecf5ff;
        i {
          color: $c-textColor;
        }
      }
      .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
      }
      .el-self-icon {
        font-size: 16px;
        width: 24px;
        text-align: center;
        margin-right: 5px;
      }
    }
    @media screen and (max-width: 768px) {
      .c-aside-scrollbar {
        .el-menu--collapse { width: 0;}
      }
    }
</style>

<template>
    <el-aside :width="'auto'">
      <div class="c-aside-scrollbar">
        <el-scrollbar>
            <el-menu v-if="sideBarItem && sideBarItem.length > 0" router :default-openeds="defaultOpend" :default-active="$route.path" class="el-menu-vertical-demo" 
              @select="selected" @open="handleOpen" @close="handleClose" :collapse="$store.getters.collapse">
              <side-bar-item v-for="(item, index) in sideBarItem" :key="Math.random() + '_' + index" :index="index" :item="item"></side-bar-item>
            </el-menu>
        </el-scrollbar>
      </div>
    </el-aside>
</template>

<script>
import { mapGetters } from 'vuex'
import SideBarItem from '../components/SideBarItem'
export default {
    name: "sideBar",
    components: {
      SideBarItem
    },
    data() {
        return {
          isCollapse: false,
          sideBarItem: null,
          defaultOpend: [this.$route.path],
          selectedPath: this.$route.path, // 当前选中的path
          selectedMenuPath: "" // 菜单选中的path
        }
    },
    provide() {
      return {
        sideBarProvide: this
      }
    },
    mounted() {
      this.getItems()
      this.openMenu()
    },
    computed: {
      ...mapGetters([
        'permission_routers'
      ])
    },
    watch: {
      "$store.getters.collapse"(v) {
        this.setSideBar(v)
      },
      "$store.getters.active_tab"(v) {
        if (v && v.path) {
          this.selectedPath = v.path
        }
      }
    },
    methods: {
      getItems() {
        this.sideBarItem = this.permission_routers
        // console.log(this.sideBarItem)
      },
      setSideBar() {
        this.isCollapse = this.$store.getters.collapse
      },
      openMenu() {
        // 当前
        if (this.$route.meta && this.$route.meta.isSkip && this.$route.matched && this.$route.matched.length > 1) {
          for (let i = this.$route.matched.length - 2; i >= 0; i--) {
            this.defaultOpend.unshift(this.$route.matched[i].path)
          }
        }
      },
      handleOpen (index) {
        // 打开菜单栏目记录path
        this.selectedMenuPath = index
      },
      handleClose (index) {
        // 关闭菜单栏目记录path 此处如果清空则收起菜单不触发事件
        this.selectedMenuPath = index
      },
      selected(index) {
        this.selectedPath = index
        this.selectedMenuPath = ""
        // console.log(index)
      }
    }
}
</script>

