<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  import * as appRegion from "@/utils/app_region";
  export default {
    name: "appInit",
    data() {
      return {}
    },
    created() {
      // 加载枚举
      this.initData()
    },
    methods: {
      async initData() {
        this.$store.dispatch("setEnumConfigs")
        await appRegion.init()
      }
    }
  }
</script>

<style lang="scss">
#app {
  height: 100%;
  font-family: '微软雅黑','宋体',arial,'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
body {
  .c-el-message-box {
    width: 300px;
  }
  .el-message {
    min-width: 360px;
  }
  .c-el-dialog {
    width: 96%;
    border-radius: 4px;
    margin: 6vh auto 0;
    .el-dialog__body {
      padding: 20px;
      .el-form--inline {
        .c-el-item-full {
          width: 100%;
        }
        .el-form-item__content {
          .el-input--suffix .el-input__inner {
            padding-right: 15px;
          }
        }
      }
    }
  }
  .c-el-loading {
    font-size: 32px;
    //z-index: 99999999 !important;
  }
  .el-select-dropdown {
    .el-select-dropdown__item {
      font-size: 14px;
    }
  }
  .el-input {
    font-size: 14px;
  }
  .c-el-input {
    width: 192px;
  }
  .el-date-picker {
    .el-time-panel {
      left: auto;
      right: 0;
    }
  }
  .c-pw50 {
    width: 50%;
  }
  .c-pw60 {
    width: 70%;
  }
  .c-pw70 {
    width: 70%;
  }
  .c-pw80 {
    width: 80%;
  }
  .c-pw100 {
    width: 100%;
  }
  .c-el-popover {
    width: 80%;
    max-width: 600px;
    padding: 0;
  }
}
@media screen and (max-width: 320px) {
  body {
    .el-message {
      min-width: 310px;
    }
    .c-el-dialog {
      .el-dialog__body {
        padding: 10px;
      }
    }
    .el-date-picker {
      width: 308px;
    }
  }
}
/* 级联单选有个选择框，不能点击lable直接选择，不方便 */
.c-no-cascader-radio {
  .el-cascader-panel .el-radio {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    top: 0px;
    right: 10px;
  }
  .el-cascader-panel .el-radio__input {
    visibility: hidden;
  }
}
/* 图片预览的关闭按钮 */
.el-image-viewer__close {
  color: #ffffff;
}
</style>
