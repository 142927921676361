import { Loading } from 'element-ui'
import * as util from '@/utils/tools'
import store from '../store'

//注册全局util
window.$util = util

let common = {
  isShowMenuIcon: false, // 是否显示菜单icon
  appPageSize: window.configs.pageSize || 10,
  appPageSizes: [10,20,30,40,50,100],
  pageRefresh: true, // 页面是否重新加载
  fullLoadings: null,
  /* 全局loading */
  fullLoading() {
    this.closeFullLoading(true)
    setTimeout(() => {
      this.fullLoadings = Loading.service({
        lock: true,
        fullscreen: true,
        spinner: "fa fa-spinner fa-pulse",
        background: "rgba(0,0,0,0.7)",
        customClass: "c-el-loading"
      })
    }, 0)
  },
  closeFullLoading(immediately) {
    if (immediately) {
      this.execCloseFullLoading()
    } else {
      setTimeout(() => {
        this.execCloseFullLoading()
      }, 10);
    }
  },
  execCloseFullLoading() {
    if (this.fullLoadings) {
      this.fullLoadings.close()
    }

  },
  // axios blob方式下载文件
  blobDownload(res, name) {
    let blob = new Blob([res], {type: res.type})
    let downloadElement = document.createElement('a')
    let href = window.URL.createObjectURL(blob); //创建下载的链接
    downloadElement.href = href;
    downloadElement.download = name; //下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放blob对象
  },
  // 请求的api地址
  appBaseApi() {
    let back = ""
    if (process.env.NODE_ENV === 'development') {
      back = window.configs.baseTestApi // process.env.VUE_APP_SiteApiUrl
    } else if (process.env.NODE_ENV === 'production') {
      if (process.env.VUE_APP_FLAG === 'pro') {
        back = window.configs.baseApi
      } else if (process.env.VUE_APP_FLAG === 'uat') {
        back = window.configs.baseUatApi
      } else if (process.env.VUE_APP_FLAG === 'test') {
        back = window.configs.baseTestApi
      } else {
        back = window.configs.baseApi
      }
    }
    return back
  },
  // 上传组件的headers
  appHeaders() {
    let headers = {
      Authorization: "Bearer " + store.getters.token
    }
    return headers
  }
}

export default common
