import request from '@/utils/request'

// 所有枚举
export function EnumInit() {
    return request({
        url: '/api/services/app/Shared/GetAllEnums',
        method: 'get'
    })
}
// 所有分类，用于分类页面的编辑使用
export function CategoryAll(data) {
    return request({
        url: '/api/services/app/Category/GetAll',
        method: 'get',
        params: data
    })
}

export function CategoryCreate(data) {
    return request({
      url: '/api/services/app/Category/Create',
      method: 'post',
      data
    })
  }

// 获取分类详情
export function CategoryDetail(data) {
    return request({
        url: '/api/services/app/Category/Get',
        method: 'get',
        params: data
    })
}

// 查询分页列表
export function CategoryList(data) {
    return request({
        url: '/api/services/app/Category/GetPagedList',
        method: 'get',
        params: data
    })
}

// 更新分类
export function CategoryUpdate(data) {
    return request({
      url: '/api/services/app/Category/Update',
      method: 'put',
      data
    })
}

// 删除分类
export function Categoryremove(data) {
    return request({
      url: '/api/services/app/Category/Delete',
      method: 'delete',
      params: data
    })
}

// 批量删除分类
export function CategoryRemoveMuliple(data) {
    return request({
        url: '/api/services/app/Category/Deletes',
        method: 'delete',
        params: data
    })
}


