/**
 *   created by cjx 2019-06-25 17:05:17
 */
//
const valids = {
  phone: /(^\d{11}$)|(^0\d{2,3}-?\d{7,8}$)/,
  phoneSimple: /^1\d{10}$/,
  password: /^\S{6,32}$/
} 
export default valids
//判断是否是空字符串
export function isNullString(s) {
  return s.replace(/(^\s*)|(\s*$)/g, '').length == 0
}
//判断是否为全角字符
export function isFull(pChar) {
  for (var i = 0; i < pChar.length; i++) {
    return pChar.charCodeAt(i) > 128
  }
}
//判断是否为数组
export function isArrayFn (value) {
  if (typeof Array.isArray === "function") {
      return Array.isArray(value);
  } else {
      return Object.prototype.toString.call(value) === "[object Array]";
  }
}
//验证url
export function isExternal(str) {
  const reg = /(https?:|mailto:|tel:)+/
  return reg.test(str)
}
//验证用户名，账户
export function validAccount(str) {
  const reg = /^[a-zA-Z0-9_]{3,16}$/
  return reg.test(str)
}
//简单验证手机号
export function validPhone(str) {
  const reg = /^1\d{10}$/
  return reg.test(str)
}
//验证密码
export function validPassword(str) {
  const reg = /^\S{6,32}$/
  return reg.test(str)
}
//验证Email
export function validEmail(str) {
  const reg = /^([A-Za-z0-9_\-.\u4e00-\u9fa5])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/
  return reg.test(str)
}
//验证身份证
export function validIDCard(str) {
  const reg = /^(\d{14}|\d{17})[0-9Xx]$/
  return reg.test(str)
}
//验证url
export function validUrl(str) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(str)
}
/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/* 大小写字母*/
export function validatAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}
