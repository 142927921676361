import axios from 'axios'
let _fullProvinces = null,
  _fullCitys = null,
  _fullCountys = null

  // 获取省
export const getProvinces = () => {
  return window.$util.clone(_fullProvinces)
}
// 获取市
export const getCitys = (provinceCode) => {
  return window.$util.clone(_fullCitys[provinceCode])
}
// 获取区
export const getCountys = (cityCode) => {
  return window.$util.clone(_fullCountys[cityCode])
}

export const getProvinceByCode = (code) => {
  var provice = _fullProvinces.find((item) => item.id == code)
  return window.$util.clone(provice)
}

export const getCityByCode = (code) => {
  var provinceCode = `${code}`.substr(0, 2) + '0000000000'
  var city = _fullCitys[provinceCode].find((item) => item.id == code)
  return window.$util.clone(city)
}

export const getCountyByCode = (code) => {
  var cityCode = `${code}`.substr(0, 4) + '00000000'
  var county = _fullCountys[cityCode].find((item) => item.id == code)
  return window.$util.clone(county)
}

const _request = async (url) => {
  try {
    let baseApi = ""
    if (process.env.NODE_ENV === 'development') {
      baseApi = window.configs.baseTestApi
    } else if (process.env.NODE_ENV === 'production') {
      if (process.env.VUE_APP_FLAG === 'pro') {
        baseApi = window.configs.baseApi
      } else if (process.env.VUE_APP_FLAG === 'uat') {
        baseApi = window.configs.baseUatApi
      } else if (process.env.VUE_APP_FLAG === 'test') {
        baseApi = window.configs.baseTestApi
      } else {
        baseApi = window.configs.baseApi
      }
    }
    var res = await axios.get(`${baseApi}${url}`)
    if (res.status == 200) {
      return res.data
    }
  } catch (error) {
    console.log("Error")
  }
  return []
}

// 初始化 
export const init = async () => {
  _fullProvinces = await _request('/areas/province.json')
  _fullCitys = await _request('/areas/city.json')
  _fullCountys = await _request('/areas/county.json')
}
