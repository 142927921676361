<style lang="scss">
    .c-tabs {
      position: absolute;
      width: 100%;
      .tab-item {
        display: inline-block;
        height: 100%;
      }
      .el-tabs__header {
        margin: 0;
      }
      .el-tabs__item {
        //margin:0 12px;
        padding: 0 12px;
        color: #888;
      }
      .el-tabs__active-bar {
        background: none !important;
      }
      .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
        padding-left: 12px;
      }
      .el-tabs--top .el-tabs__item.is-top:last-child {
        padding-right: 12px;
      }
      .el-tabs__item.is-active {
        border-left: 1px solid #efefef;
        border-right: 1px solid #efefef;
        border-bottom: 2px solid $c-bgColor;
        color: $c-textColor;
        background: #f8f8f8;
      }
      .el-tabs__item:focus.is-active.is-focus:not(:active) {
        box-shadow: none;
      }
      .el-tabs__content {
        display: none;
      }
      .el-tabs__item.is-closable .el-icon-close {
        display: none;
      }
      .el-tabs__item.is-active.is-closable .el-icon-close,
      .el-tabs__item.is-closable:hover .el-icon-close {
        display: inherit;
      }
      .el-tabs__item.is-active.is-closable:nth-child(2) .el-icon-close,
      .el-tabs__item.is-closable:nth-child(2):hover .el-icon-close {
        display: none;
      }
    }
</style>

<template>
    <div class="c-tabs">
        <el-tabs @tab-click="barTabClick" @tab-remove="barTabRemove" :value="activeItem.path" closable>
            <template v-for="(item, index) in $store.getters.tabPanes">
              <el-tab-pane :key="item.path" :name="item.path">
                <span class="tab-item" slot="label" @contextmenu.prevent="(e) => { tabRightClick(e, item, index) }">
                  <i :class="item.meta && item.meta.icon || ''" v-if="isShowIcon"></i> 
                  {{ item.title }}
                </span>
              </el-tab-pane>
            </template>
        </el-tabs>
        <context-menu :ref="refContextMenu" :pos="menuPos"></context-menu>
    </div>
</template>

<script>
import { getActiveTabStorage } from '@/utils/storages'
import ContextMenu from '../components/ContextMenu'
export default {
    name: "tabBar",
    components: {
      ContextMenu
    },
    data() {
        return {
          isShowIcon: null,
          refContextMenu: "refContextMenuDropdown",
          menuPos: [0, 0],
          activeItem: getActiveTabStorage() || this.$store.getters.tabPanes[0]
        }
    },
    mounted() {
      this.isShowIcon = window && window.$common.isShowMenuIcon
    },
    watch: {
      "$store.getters.active_tab"(v) {
        if (v) {
          this.activeItem = v
          this.$store.dispatch("addTagsInclude", v)
        }
      },
      "$route.path"() {
        this.setCurTab()
      }
    },
    created() {
      this.setCurTab()
    },
    methods: {
      setCurTab() {
        // 获取存储的tab和当前路由的item
        let tabStorage = getActiveTabStorage()
        let routeItem = {
          title: this.$route && this.$route.meta && this.$route.meta.title,
          name: this.$route && this.$route.name || "",
          path: this.$route && this.$route.path || "",
          meta: this.$route && this.$route.meta || ""
        }
        let temp = null
        if (tabStorage && tabStorage.path) {
          // 优先以route为准
          if (tabStorage.path !== routeItem.path) {
            temp = routeItem
          } else {
            temp = tabStorage
          }
        } else {
          temp = routeItem
        }
        // 参数重置
        temp.query = this.$route && this.$route.query || {}
        temp.params = this.$route && this.$route.params || {}
        // 设置当前选中显示的tab标签
        this.$store.dispatch("setActiveTab", temp)
        // 添加到tab标签
        this.$store.dispatch("addTabPanes", temp)
        //如果是移动设备，点击菜单后消失
        if (this.$store.getters.device == "mobile") {
          this.$store.dispatch("setCollapse", true)
        }
      },
      barTabClick(data) {
        /* let temp = {
          title: data.label,
          name: data.$route.name,
          path: data.$route.path
        } */
        let temp = data.index ? this.$store.getters.tabPanes[data.index] : data
        this.activeItem = temp //设置当前激活对象
        this.$store.dispatch("setActiveTab", temp) // 设置激活tab的state
        this.$router.push({ path: temp.path, query: temp.query }) // 添加到路由表
        this.$store.dispatch("addTagsInclude", temp) // tab点击记录缓存，下次不用刷新
        // 标签切换不刷新页面
        window.$common.pageRefresh = false
      },
      barTabRemove(data) {
        this.$store.dispatch("deleteTabPanes", data).then(() => {
          this.$router.push({ path: this.activeItem.path }) // 关闭移除当前标签
        })
        // 关闭时屏蔽此处，则打开过的缓存不清理，关闭重新打开需要手动刷新，两处要同步 ContextMenu.vue ---- closeTabs方法
        this.$store.dispatch("deleteTagsInclude", [this.activeItem.name]) // 删除当前缓存，下次打开会刷新
      },
      tabRightClick(e, data, index) {
        // 同时也选中
        this.barTabClick(data)
        // 获取坐标，调用右键
        this.menuPos = [e.clientX, e.clientY]
        this.$refs[this.refContextMenu].show()
        this.$refs[this.refContextMenu].currentItem = data
        this.$refs[this.refContextMenu].currentIndex = index
        return false
      }
    }
}
</script>

