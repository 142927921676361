export default {
  data() {
    return {
      skinThemeNames: [
        "0BC9A9",
        "0E66D3",
        "05AD80",
        "6D15E1",
        "07B5D0",
        "07CCB8",
        "9D15E1",
        "29C203",
        "84C404",
        "409EFF",
        "121212",
        "650606",
        "696969",
        "D00EE1",
        "D60AA3",
        "E88E11",
        "EA4C3B",
        "F75B43"
      ]
    }
  },
  methods: {
    // 根据存储的颜色判断主题
    getSkinByState() {
      let val = this.$store.getters.skin_color_code || "05AD80"
      let index = this.skinThemeNames.indexOf(val)
      if (index >= 0) {
        this.changeSkin(index)
      }
    },
    // 选中颜色替换皮肤
    changeSkin(index, callback) {
      let indexPath = this.skinThemeNames[index] + "/index.css"
      let cSkinPath = this.skinThemeNames[index] + "/c-skin.css"
      this.loadCss(indexPath)
      this.loadCss(cSkinPath)
      if (callback) {
        callback()
      }
      // 存储颜色值
      this.$store.dispatch("setSkinColorCode", this.skinThemeNames[index])
      // 移除之前的皮肤，暂时不考虑清除
      /* let links = document.getElementsByTagName("link")
      for (let i in links) {
        let link = links[i]
        if (link.name === "c-skin" && link.href.index(indexPath) >= 0) {
          link.parentNode.removeChild(link)
        }
      } */
    },
    // 加载皮肤css
    loadCss(cssPath) {
      var path = "themes/" + cssPath
      var head = document.getElementsByTagName("head")[0]
      var link = document.createElement("link")
      link.href = path
      link.name = "c-skin"
      link.rel = "stylesheet"
      link.type = "text/css"
      head.appendChild(link)
    }
  }
}
