import request from '@/utils/request'

// 系统配置
export function getSystemSettings() {
  return request({
    url: '/api/services/app/Configuration/GetSystemSettings',
    method: 'get'
  })
}

export function updateSystemSettings(data) {
  return request({
    url: '/api/services/app/Configuration/ChangeSystemSettings',
    method: 'post',
    data
  })
}

// 自定义配置
export function getCustomerSettings() {
  return request({
    url: '/api/services/app/CustomerSettings/Get',
    method: 'get'
  })
}

export function updateCustomerSettings(data) {
  return request({
    url: '/api/services/app/CustomerSettings/Update',
    method: 'put',
    data
  })
}

// 上传文件
export function UploadFile(data) {
  return request({
    url: '/api/services/app/Shared/UploadFile',
    method: 'post',
    data
  })
}

// 获取上传文件列表
export function FileList(data) {
  return request({
    url: '/api/services/app/Shared/FileList',
    method: 'get',
    params: data
  })
}

// 删除上传的文件
export function DeleteFile(data) {
  return request({
    url: '/api/services/app/Shared/DeleteFile',
    method: 'delete',
    params: data
  })
}

// 发送验证码
export function SendMobileCode(data) {
  return request({
    url: '/api/services/app/Shared/SendMobileCode',
    method: 'post',
    data
  })
}

// 获取枚举定义的值及显示名称
export function GetEnums() {
  return request({
    url: '/api/services/app/Shared/GetEnums',
    method: 'get',
    params: data
  })
}

// 获取全部枚举
export function GetAllEnums() {
  return request({
    url: '/api/services/app/Shared/GetAllEnums',
    method: 'get',
    params: data
  })
}