import request from '@/utils/request'

// 用户管理
export function create(data) {
  return request({
    url: '/api/services/app/Admin/Create',
    method: 'post',
    data
  })
}

export function getDetail(data) {
  return request({
    url: '/api/services/app/Admin/Get',
    method: 'get',
    params: data
  })
}

export function getAll(data) {
  return request({
    url: '/api/services/app/Admin/GetPagedList',
    method: 'get',
    params: data
  })
}

export function update(data) {
  return request({
    url: '/api/services/app/Admin/Update',
    method: 'put',
    data
  })
}

export function resetPassword(data) {
  return request({
    url: '/api/services/app/Admin/ResetPassword',
    method: 'post',
    data
  })
}

export function remove(data) {
  return request({
    url: '/api/services/app/Admin/Delete',
    method: 'delete',
    params: data
  })
}

export function removeMuliple(data) {
  return request({
    url: '/api/services/app/Admin/Deletes',
    method: 'delete',
    params: data
  })
}

// 绑定代理
export function SetAgents(data) {
  return request({
    url: '/api/services/app/Admin/SetAgents',
    method: 'post',
    data
  })
}

// 绑定商家
export function SetMerchants(data) {
  return request({
    url: '/api/services/app/Admin/SetMerchants',
    method: 'post',
    data
  })
}

// 登陆日志
export function getLoginLogs(data) {
  return request({
    url: '/api/services/app/User/GetLoginLogs',
    method: 'get',
    params: data
  })
}

// 操作日志
export function getAuditLogs(data) {
  return request({
    url: '/api/services/app/User/GetAuditLogs',
    method: 'get',
    params: data
  })
}

export function changePassword(data) {
  return request({
    url: '/api/services/app/User/ChangePassword',
    method: 'post',
    data
  })
}
