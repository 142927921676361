<style scoped lang="scss">
  /deep/ .c-dropdown-menu {
    position: fixed;
    z-index: 9999999999999;
  }
</style>

<template>
  <div>
    <ul :id="objId" class="el-dropdown-menu el-popper c-dropdown-menu" v-if="showMenu" :style="posClass">
      <li class="el-dropdown-menu__item" @click="refreshPage()"><i class="el-icon-refresh-right"></i>刷新</li>
      <li class="el-dropdown-menu__item" @click="refreshPageAll()"><i class="el-icon-refresh"></i>刷新全部</li>
      <li class="el-dropdown-menu__item" @click="closePage()" v-if="isForbidClose()"><i class="el-icon-circle-close"></i>关闭当前</li>
      <li class="el-dropdown-menu__item" @click="closePageLeft()" v-if="isForbidClose()"><i class="el-icon-back"></i>关闭左侧标签</li>
      <li class="el-dropdown-menu__item" @click="closePageRight()"><i class="el-icon-right"></i>关闭右侧标签</li>
      <li class="el-dropdown-menu__item" @click="closePageOther()"><i class="el-icon-remove-outline"></i>关闭其他标签</li>
      <li class="el-dropdown-menu__item" @click="closePageAll()"><i class="el-icon-switch-button"></i>关闭全部</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "contextMenu",
  inject: ["refreshRouterView"],
  props: {
    pos: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  data() {
    return {
      objId: "contextMenu",
      currentItem: null, // 当前右击对象
      currentIndex: 0, // 当前index
      showMenu: false,
      posClass: {
        left: 0,
        top: 0
      }
    }
  },
  mounted() {
    // 监控点击事件
    window.addEventListener('click', event => {
      const e = event || window.event
      const cm = e.target.closest("#" + this.objId)
      if (!cm || cm.length === 0) {
        this.hide()
      }
    });
  },
  methods: {
    // 判断是否禁止关闭，只有dashboard禁止关闭
    isForbidClose() {
      let isClose = true
      if (this.currentIndex === 0) {
        isClose = false
      }
      return isClose
    },
    // 刷新当前
    refreshPage() {
      this.refreshRouterView()
      this.hide()
    },
    // 刷新全部
    refreshPageAll() {
      // 相当于全部refresh了
      // 记录的状态不存在，因此直接当成全部刷新用
      this.$router.replace({
        path: '/refresh',
        query: {
          t: Date.now()
        }
      })
      this.hide()
    },
    // 关闭当前标签
    closePage(tab) {
      let item = tab || this.currentItem
      let path = item && item.path
      if (path) {
        this.$parent.barTabRemove(path)
      }
      this.hide()
    },
    // 关闭左侧标签
    closePageLeft() {
      if (this.currentIndex <= 1) {
        this.alert("左侧首页不能关闭!")
      } else {
        this.closeTabs([1, this.currentIndex - 1])
      }
      this.hide()
    },
    // 关闭右侧标签
    closePageRight() {
      this.closeTabs([this.currentIndex + 1])
      this.hide()
    },
    // 关闭其他标签
    closePageOther() {
      // 先删除右侧--再删除左侧，不会影响index的变化
      this.closeTabs([this.currentIndex + 1])
      if (this.currentIndex > 1) {
        this.closeTabs([1, this.currentIndex - 1])
      }
      this.hide()
    },
    // 关闭全部
    closePageAll() {
      if (this.currentIndex > 0) {
        // 先删除其他--再删除当前，可以保证选中到前一个
        this.closePageOther()
        this.closePage(this.$store.getters.tabPanes[1])
      } else {
        this.closePageOther()
      }
      this.hide()
    },
    // 关闭多个标签
    closeTabs(arr) {
      // 需要删除缓存，下次打开重新刷新数据
      let tabPanes = this.$store.getters.tabPanes
      let tabLen = tabPanes.length
      let tagNames = [] // 需要删除的tags
      arr[1] = arr && arr[1] || (tabLen - 1)
      for (let i = arr[0]; i <= arr[1]; i++) {
        tagNames.push(tabPanes[i].name)
      }
      // 关闭标签数组
      console.log(tagNames)
      this.$store.dispatch("deleteTabPanesArray", arr)
      // 关闭时屏蔽此处，则打开过的缓存不清理，关闭重新打开需要手动刷新，两处要同步 TabBar.vue ---- barTabRemove方法
      this.$store.dispatch("deleteTagsInclude", tagNames) // 删除当前缓存，下次打开会刷新
    },
    show() {
      this.hide()
      setTimeout(() => {
        this.showMenu = true
        this.$nextTick(() => {
          let fixedExcursion = 10 // 固定多偏移的像素值
          let bw = $(document.body).outerWidth(true) // body宽
          let bh = $(document.body).outerHeight(true) // body高
          let w = $("#" + this.objId).width() // 宽
          let h = $("#" + this.objId).height() // 高
          let ofx = this.pos[0] + fixedExcursion // x偏移
          let ofy = this.pos[1] + fixedExcursion // y偏移
          // x坐标计算
          if (bw <= w) {
            ofx = 0 // 如果body宽小于弹窗宽
          } else if (bw - ofx <= w) {
            ofx = bw - w - fixedExcursion // 如果偏移后显示不全，重设x偏移
          }
          // y坐标计算
          if (bh <= h) {
            ofy = 0
          } else if (bh - ofy <= h) {
            ofy = bh - h - fixedExcursion
          }
          // 设置显示位置
          this.posClass.left = ofx + "px"
          this.posClass.top = ofy + "px"
          console.log(this.posClass)
        })
      }, 100)
    },
    hide() {
      this.showMenu = false
    }
  }
}
</script>