import '@/assets/css/CssTools.css'
import Vue from 'vue'
import App from './App.vue'
import VueAMap from 'vue-amap'
import axios from 'axios'
import vueAxios from 'vue-axios'
import '@/utils/registerServiceWorker'
import ElementUI from 'element-ui'
import '@/assets/css/element-variables.scss'
import 'font-awesome/css/font-awesome.css'
import common from '@/utils/common'
import mixins from './mixins/mixins'
import store from './store'
import router from './router'
import echarts from 'echarts'
// import '@/mock'
import Print from 'vue-print-nb'

// 挂载echarts
Vue.prototype.$echarts = echarts

// 注册common
window.$common = common

Vue.use(ElementUI)
Vue.use(VueAMap)
// 初始化
VueAMap.initAMapApiLoader({
  key: 'b5ae61fd63cc0cff6233807172b3a73a&plugin=AMap.ToolBar',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.Geolocation','Autocomplete','AMap.Geocoder'], // 依赖配置，根据自己的需求引入
  // 默认高德 sdk 版本为 1.4.4
})
Vue.use(Print)
Vue.use(vueAxios, axios)

//混入
Vue.mixin(mixins)

Vue.config.productionTip = false

var vue = new Vue({
  // el: '#app',
  router,
  store,
  data: {
    eventHub: new Vue()
  },
  render: h => h(App)
}).$mount("#app")

export default vue

